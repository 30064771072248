import { defineStore } from "pinia";
import { getUser } from "@/api/member";
import { getConfiguration } from "@/api/configure";

export const menuStore = defineStore('menu', {
    state: () => {
        return {
            menus: [],
            open: false,
            current_path: '/'
        }
    },
    getters: {},
    actions: {}
});


export const userStore = defineStore('', {
    state: () => {
        return {
            user: undefined,
            role: 'member',
            name: '',
            fullName: '',
            language: 'en',
            isAdmin: false,
            projects: [],
            project: ''
        }
    },
    getters: {
        getUserInfo: (state) => state
    },
    actions: {
        setUserInfo(username) {
            this.name = username
            return getUser(username).then((res) => {
                this.role = res.data.role
                this.fullName = res.data.full_name
                if (res.data.projects.length > 0) {
                    this.projects = res.data.projects
                }
                if (this.role === 'admin') {
                    this.isAdmin = true
                }
                return res.data.role
            })
        },
        setProject(project) {
            this.project = project
        }
    }
});

export const configStore = defineStore('config', {
    state: () => ({
      perforceConfiguration: {
        port: '',
        stream: '',
        username: '',
        password: '',
        uprojectPath: '',
      },
      packageEditorConfig: {
        editorDownloadType: 'Static',
        editorAddress: '',
        packageDownloadType: 'Static',
        packageAddress: '',
      },
      headerList: []
    }),
    actions: {
        setConfigInfo(username, projectName) {
            let allConfigs = getConfiguration()
            let projectConfig = allConfigs.filter(item => item.project.includes(projectName))

            this.headerList = projectConfig[0].headerList
            this.perforceConfiguration = projectConfig[0].perforceConfiguration;
            this.packageEditorConfig = projectConfig[0].packageEditorConfig;
            return true
        
        },
        addHeader(header) {
            this.headerList.push(header);
        },
        deleteHeader(index) {
            this.headerList.splice(index, 1);
        },
        updatePerforceConfig(newConfig) {
            this.perforceConfiguration =  newConfig;
        },
        updatePackageEditorConfig(newConfig) {
            this.packageEditorConfig = newConfig;
        },
    },
  });


export const reportInfoStore = defineStore('reportInfo', {
    state: () => {
        return {
            info: {}
        }
    },
    getters: {},
    actions: {}
})

export const useChangeList = defineStore('changeList', {
    state: () => {
        return {
            currentChangeList: {}
        }
    },
    persist: {
        enabled: true
    },
    actions: {
        setCurrentChangeList(data) {
            this.currentChangeList = data
        }
    }
})

export const useReports = defineStore('reports', {
    state: () => {
        return {
            reports: {},
            currentReport: []
        }
    },
    // persist: {
    //     enabled: true
    // },
    actions: {
        setReports(data) {
            this.reports = data
        },
        setCurrentReport(data) {
            this.currentReport = data
        }
    }
})

export const useRecords = defineStore('records', {
    state: () => {
        return {
            records: [],
            currentRecord: {}
        }
    },
    // persist: {
    //     enabled: true
    // },
    actions: {
        setRecords(data) {
            this.records = data
        },
        setCurrentRecord(data) {
            this.currentRecord = data
        }
    }
})

export const useNodeData = defineStore('nodeData', {
    state: () => {
        return {
            currentNodeData: undefined
        }
    },
    // persist: {
    //     enabled: true
    // },
    actions: {
        setCurrentNodeData(data) {
            this.currentNodeData = data
        }
    }
})

export const useIsUserClick = defineStore('isUserClick', {
    state: () => {
        return {
            isUserClick: undefined
        }
    },
    // persist: {
    //     enabled: true
    // },
    actions: {
        setIsUserClick(data) {
            this.isUserClick = data
        }
    }
})

// 定义project pinia状态管理
export const useProject = defineStore('project', {
    state: () => {
        return {
            id: null,
            name: null
        }
    }
    // actions: {
    //     updateProject({ id, name }) {
    //         this.id = id
    //         this.name = name
    //     }
    // }
})



import { http } from "../request";

export function getConfiguration(username) {
    let configuration = 
        [{  project: 'NeoX',
            perforceConfiguration: {
                port: 55555,
                stream: 45,
                username: 'Jimmy',
                password: 'John',
                uprojectPath: null,
            },
            packageEditorConfig: {
                editorDownloadType: 'Static',
                editorAddress: null,
                packageDownloadType: 'Dynamic',
                packageAddress:null,
            },
            headerList: [
                {
                    key: 'key1',
                    value: 10,
                    description: "First Item"
                    },
                    {
                    key: 'key2',
                    value: 20,
                    description: "Second Item"
                    },
                    {
                    key: 'key3',
                    value: 30,
                    description: "Third Item"
                    }
            ],
        },
        {   project: 'MeiThirdPerson',
            perforceConfiguration: {
                port: 444444,
                stream: 57,
                username: 'Jersey',
                password: 'Mikes',
                uprojectPath: null,
            },
            packageEditorConfig: {
                editorDownloadType: 'Dynamic',
                editorAddress: null,
                packageDownloadType: 'Static',
                packageAddress:null,
            },
            headerList: [
                {
                    key: 'key4',
                    value: 40,
                    description: "Fourth Item"
                    },
                    {
                    key: 'key5',
                    value: 50,
                    description: "Fifth Item"
                    },
                    {
                    key: 'key6',
                    value: 60,
                    description: "Sixth Item"
                    }
            ],
        },

    ]

    return configuration
}
<template>
  <el-container>
    <el-aside
      :class="open ? ' w-custom-4 border-r-2 border-fuchsia-600 bg-menus duration-700' : ' w-custom-18 border-r-2 border-fuchsia-600 bg-menus duration-700'">
      <Navigate />
    </el-aside>
    <el-container>
      <el-header class="border-b-2 border-fuchsia-600 ">
        <div class="flex w-full mt-2 items-center">
          <div class="inline-block expand-icon" @click="handleClickOpen">
            <el-icon>
              <component :is="open ? 'Expand' : 'Fold'" />
            </el-icon>
          </div>
          <div class="inline-block project ml-4 flex-grow">
            <el-form-item label="Project">
              <el-select v-model="selectProject" @change=handleSelectChange>
                <el-option v-for="item in projects" :label="item.display_name" :value="item.name" :key="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="mr-3 flex items-center" id="user">
            <div id="documents" class="float-left mt-3 mr-3">
              <el-link :href="getDocsURL()" target="_blank">
                <el-icon :size="28">
                  <Document />
                </el-icon>
                Docs
              </el-link>
            </div>
            <Lang class="float-left mr-3" />
            <el-popover :width="150">
              <template #reference>
                <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
              </template>
              <template #default>
                <div class="text-center">
                  <p class="border-b-2"> {{ name }} </p>
                  <p @click="logout" class="cursor-pointer"> Logout </p>
                </div>
              </template>
            </el-popover>
          </div>
        </div>

        <!-- <div id="user" class="float-right mt-2 mr-3">
          <div id="documents" class="float-left mr-3 mt-3">
            <el-link :href="getDocsURL()" target="_blank">
              <el-icon :size="28">
                <Document />
              </el-icon>
              Docs
            </el-link>
          </div>
          <Lang class="float-left mr-3" />
          <el-popover :width="150">
            <template #reference>
              <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
            </template>
            <template #default>
              <div class="text-center">
                <p class="border-b-2"> {{ name }} </p>
                <p @click="logout" class="cursor-pointer"> Logout </p>
              </div>
            </template>
          </el-popover>
        </div> -->
      </el-header>
      <el-main class="bg-gray-200 w-full p-3">
        <router-view :key="changeProjectKey" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Navigate from "@/components/nav/Navigate";
import Lang from '@/components/languageCompoent'
import { menuStore, userStore, configStore } from "@/store";
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { removeUsername, removeAccessToken, removeSession } from "@/request/cookieConfig";
import { nextTick } from 'vue';

export default {
  name: "Layout",
  components: {
    Navigate,
    Lang
  },
  setup() {
    const menus_store = menuStore();
    const { menus, open } = storeToRefs(menus_store);
    const user_store = userStore()
    const route = useRoute()
    const router = useRouter()
    const { user, name, projects, project } = storeToRefs(user_store)
    return { menus, open, user_store, user, name, projects, project, route, router }
  },
  data() {
    return {
      selectProject: '',
      changeProjectKey: 0,
    }
  },
  created() {
    this.selectProject = this.project
    console.log(this.project)
    this.updateMenus()
  },
  methods: {
    handleClickOpen() {
      new menuStore().open = !new menuStore().open;
    },
    logout() {
      // remove from cookie
      removeUsername()
      removeAccessToken()
      removeSession()
      // redirect url
      window.location = process.env.VUE_APP_BASE_URL + "/login?return_url=" + process.env.VUE_APP_BASE_URL
    },
    getDocsURL() {
      const language = this.$i18n.locale
      let docsURL = `${process.env.VUE_APP_BASE_URL}/docs/${language}/index.html`
      return docsURL
    },
    handleSelectChange() {
      this.user_store.setProject(this.selectProject)
      this.$router.push({
        path: "/changeLists",
        query: { ...this.route.query, project: this.selectProject, page: 1 }
      })
      this.changeProjectKey++
      this.updateMenus();
    },
    updateMenus() {
      //console.log("Updating menus attempt");
      for (let i = 0; i < this.user_store.projects.length; i++) {
        //find current project parameters. this.user_store.project only holds the name of the current project
        if (this.user_store.project == this.user_store.projects[i].name)
        {
          //if project doesn't show the 'Case' tab, set its visibility to false
          if(this.user_store.projects[i].show_case == false)
          {
            for (let i = 0; i < this.menus.length; i++)
            {
              if(this.menus[i].name == "Case"){
                this.menus[i].visible = false;
              }
            }
          }
          //if the project does show the 'Case' tab, set its visibility to true
          else if (this.user_store.projects[i].show_case == true) {
            for (let i = 0; i < this.menus.length; i++)
            {
              if(this.menus[i].name == "Case"){
                this.menus[i].visible = true;
              }
            }
          }
          //if project doesn't show the 'Case Group' tab, set its visibility to false
          if(this.user_store.projects[i].show_case_group == false)
          {
            for (let i = 0; i < this.menus.length; i++)
            {
              if(this.menus[i].name == "Case Group"){
                this.menus[i].visible = false;
              }
            }
          }
          //if the project does show the 'Case Group' tab, set its visibility to true
          else if(this.user_store.projects[i].show_case_group == true) {
            for (let i = 0; i < this.menus.length; i++)
            {
              if(this.menus[i].name == "Case Group"){
                this.menus[i].visible = true;
              }
            }
          }
          return
        }
      }
    },
  }

}
</script>

<style>
.el-container {
  height: 100%;
}

.project {
  text-align: start;
}

.project .el-form-item {
  margin-bottom: 0;
}

.project .el-select {
  width: 200px;
}

.expand-icon {
  width: 16px;
  height: 16px;
}


/*.right-menu-item {*/
/*  display: inline-block;*/
/*  padding: 0 8px;*/
/*  height: 50%;*/
/*  font-size: 18px;*/
/*  color: #5a5e66;*/
/*  vertical-align: text-bottom;*/
/*}*/
/*.hover-effect {*/
/*  cursor: pointer;*/
/*  transition: background .3s;*/
/*}*/
/*.right-menu-item:hover {*/
/*   background: rgba(0, 0, 0, .025)*/
/* }*/
</style>

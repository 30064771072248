export default {
  "menu": {
    "case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケース"])},
    "case_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケース集"])},
    "test_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テストレポート"])}
  },
  "tab": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル名"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成者"])},
    "jenkins_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenkins url"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
    "last_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終更新日時"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])}
  },
  "btn": {
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実行"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "new_case_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケース集作成"])},
    "new_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テスト作成"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビルド"])}
  },
  "login": {
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])}
  },
  "field": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始時刻"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了時刻"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["継続時間"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステップ"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
    "case_set_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケース集の名"])},
    "download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログをダウンロード"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート"])}
  },
  "message": {
    
  }
}
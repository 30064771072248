export default {
  "menu": {
    "case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case"])},
    "case_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case set"])},
    "case_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Group"])},
    "test_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Reports"])},
    "change_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change List"])},
    "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
    "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Configure"])}
  },
  "tab": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "jenkins_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenkins url"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "last_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases"])},
    "teamcity_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamcity url"])}
  },
  "btn": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "new_case_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New case set"])},
    "new_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New test"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build"])},
    "boot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boot"])},
    "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboot"])},
    "shutdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shutdown"])}
  },
  "login": {
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "field": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game log file"])},
    "infoLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case log"])},
    "errorLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error log"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
    "case_set_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case set name"])},
    "download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download log file"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
  },
  "message": {
    
  }
}
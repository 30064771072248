<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <el-icon class="w-full h-full">
        <Icon style="width: 3rem; height: 1.5rem; margin-top: 15px" icon="mdi:spoken-language" />
      </el-icon>
    </div>
    <template #dropdown>
      <el-dropdown-menu >
        <el-dropdown-item :disabled="language === 'zh'" command="zh">
          中文
        </el-dropdown-item>
        <el-dropdown-item :disabled="language === 'en'" command="en">
          English
        </el-dropdown-item>
        <!--      <el-dropdown-item :disabled="language==='es'" command="es">-->
        <!--        Español-->
        <!--      </el      -dropdown-item>-->
        <el-dropdown-item :disabled="language === 'ja'" command="ja">
          日本語
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import {userStore} from "@/store";
import { Icon } from '@iconify/vue'

export default {
  name: "index",
  components: {
    Icon
  },
  computed: {
    language() {
      const user_store = new userStore()
      return user_store.language
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      const user_store = userStore()
      user_store.language = lang
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>

</style>

export default {
  "menu": {
    "case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测试例子"])},
    "case_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测试集"])},
    "test_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测试报告"])}
  },
  "tab": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "file_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件名"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建者"])},
    "jenkins_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenkins url"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
    "last_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新时间"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])}
  },
  "btn": {
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
    "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运行"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
    "new_case_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新测试集"])},
    "new_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新测试"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
    "build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["构建"])}
  },
  "login": {
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出登录"])}
  },
  "field": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待中"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始时间"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束时间"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持续时间"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤"])},
    "case_set_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测试集名"])},
    "download_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载日志文件"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告"])}
  },
  "message": {
    
  }
}
<template>
    <div>
        <img v-if="result === 'pending'" :src="pendingIcon" class="pending-icon"/>
        <img v-else-if="result === 'running'" :src="runningIcon" />
    </div>
</template>
  
<script>
import pendingIcon from '../../assets/pending.svg'
import runningIcon from '../../assets/running.svg'
export default {
    props: {
        result: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // 将导入的 SVG 文件赋值给 data 属性
            pendingIcon,
            runningIcon
        }
    }
}
</script>

<style scoped>
</style>
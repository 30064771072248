import axios from "axios"
import { applyResponseInterceptor } from '@/request/axiosInstance/interceptors'

const deviceFarmHttp = axios.create({
    baseURL: process.env.VUE_APP_BASE_DEVICE_API,
    timeout: 6000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
})

// 请求拦截器
deviceFarmHttp.interceptors.request.use(
    // 请求前配置信息
    function (config) {
        config.headers['X-ADMIN-TOKEN'] = "cgX0D0eOynTQ4OZa"
        return config
    },
    // 请求错误应该响应什么内容
    function (error) {
        return Promise.reject(error)
    }
)

// 响应拦截器
applyResponseInterceptor(deviceFarmHttp)

export default deviceFarmHttp
<template>
    <router-view />
</template>

<script>
    export default {
        name: 'EmptyParent',
    }

</script>

<style>
</style>
import { createApp } from 'vue';
import { createPinia } from "pinia";
import MyIcon from './components/icon/recordResultIcon.vue';
import router from "./router";
import piniaPersist from 'pinia-plugin-persist'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from  '@element-plus/icons-vue';

import App from './App.vue';

import './index.css';
import './assets/tailwind.css'

// oktaOauth oauth
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import authConfig from '@/oktaOauth/config'

// video
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import i18n from './i18n'

const pinia = createPinia();
pinia.use(piniaPersist)
const app = createApp(App)

// 引入 ElementPlus
app.use(ElementPlus);

// 引入 vue的存储库 pinia
app.use(pinia);

app.component('my-icon', MyIcon);

// 引入图标库
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 引入路由组件
app.use(router);

// 引入okta sso
const oktaAuth = new OktaAuth(authConfig.oidc)
app.use(OktaVue, {oktaAuth})

// 引入 video组件
app.use(Vue3VideoPlayer, {
    lang: 'en'
})

// 引入多语言
app.use(i18n)

const vueApp = app.mount('#app');

export default vueApp

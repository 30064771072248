import axios from "axios"
import { getACCESS_TOKEN, getREFRESH_TOKEN } from "@/request/cookieConfig"
import { applyResponseInterceptor } from '@/request/axiosInstance/interceptors'
import { userStore } from "@/store";



const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 6000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
})



// 请求拦截器
http.interceptors.request.use(
    // 请求前配置信息
    function (config) {
        const user_store = userStore()
        if (process.env.NODE_ENV === "production") {
            config.headers['X-ACCESS-TOKEN'] = getACCESS_TOKEN()
            config.headers['X-REFRESH-TOKEN'] = getREFRESH_TOKEN()
        }
        else if (process.env.NODE_ENV === "development") {
            config.headers['X-ADMIN-TOKEN'] = "cgX0D0eOynTQ4OZa"
        }
        config.headers['PROJECT-NAME'] = user_store.project
        return config
    },
    // 请求错误应该响应什么内容
    function (error) {
        return Promise.reject(error)
    }
)

// 响应拦截器
applyResponseInterceptor(http)

export default http
// eslint-disable-next-line no-unused-vars
import { createRouter, createWebHistory } from "vue-router";
import { createRouters } from "@/router/route";
// import { navigationGuard } from "@okta/okta-vue";
import { menuStore, userStore } from "@/store";
import { getUsername, setAccessToken, setUsername, setOriginUrl, getOriginUrl } from "@/request/cookieConfig";
import vueApp from "@/main";
// import {menuStore, userStore} from "@/store";
// import { getMenus } from "@/api/system";
// import DwUtils from "../util";

// 对外服务工具组前端模板工具库
// const util = new DwUtils();

const router = createRouter({
    // history: createWebHashHistory(), // 本地开发使用hash模式
    history: createWebHistory(), // 上线的时候记得更替为History模式
    routes: createRouters
});
// const not_auth = ['/login', '/logout']
// const nav = process.env.NODE_ENV === 'production' ? navigationGuard : () => {}

// router.beforeEach(nav)
router.beforeEach((to, from, next) => {
    let username = getUsername()
    const user_store = new userStore()
    if (username === undefined && !to.query.at) {
        // not login
        // window.location=process.env.VUE_APP_BASE_URL+"/login?return_url="+process.env.VUE_APP_BASE_URL
        window.location = process.env.VUE_APP_BASE_URL + "/login?return_url=" + process.env.VUE_APP_RETURN_URL
        return false
    } else if (to.query.at) {
        // first login
        user_store.name = to.query.na
        username = to.query.na
        setUsername(to.query.na)
        setAccessToken(to.query.at)
        const origin_url = getOriginUrl()
        if (origin_url === undefined) {
            vueApp.$router.push('/')
        } else {
            vueApp.$router.push(origin_url)
        }
    }

    const menu = menuStore()
    user_store.setUserInfo(username).then((res) => {
        let value
        if (res === 'member') {
            value = createRouters.filter((item) => {
                return item.path !== '/member'
            })
        } else {
            value = createRouters
        }
        if (menu.menus.length === 0) {
            value.forEach((item) => {
                if (item.path !== '/' && item.path !== '/login/callback' && item.path !== '/login' && item.path !== '/manage') {
                    menu.menus.push(item.children[0])
                }
            })
        }

        if (to.path.indexOf('info') > -1) menu.current_path = to.path.split('/')[0]
        menu.current_path = to.path
        setOriginUrl(to.path)
        to.meta.prevRoute = from.fullPath
        // const project = user_store.project
        if (to.path === '/changeLists' && !to.query.page) {
            next({ path: to.path, query: { ...to.query, page: 1 } })
        }
        else if (to.path === '/manage') {
            next({ path: '/manage/device' })
        }
        else if (to.query.project) {
            if (!user_store.project) {
                user_store.setProject(to.query.project)
            }
            next()
        }
        else {
            if (!user_store.project) {
                user_store.setProject(user_store.projects[0].name)
            }
            next({
                path: to.path,
                query: { ...to.query, project: user_store.project }
            })
        }
    })


})


export default router;

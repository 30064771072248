<template>
    <template  v-for="(item, index) in menus" :key="item.path"  >
        <!--树枝-->
        <template v-if="item.visible !== false">
            <template class="hover:bg-hoverMenus focus:bg-hoverMenus" v-if=" item.children.length && item.hidden">
                <el-sub-menu
                        :key="item.path + '_' + index"
                        :index="item.path"
                >
                    <template #title>
                        <el-icon v-if="item.meta" >
                            <component :is= 'item.meta.icon' class="menu-icon">
                            </component>
                        </el-icon>
                        <span class="text-indigo-200 font-medium">{{ $tc('menu.' + item.name.replace(" ", "_").toLowerCase()) }}</span>
                    </template>
                    <!--递归子菜单-->
                    <menuTree  :menus="item.children"/>
                </el-sub-menu>
            </template>
            <!--树叶-->
            <el-menu-item
                          :index="item.path"
                          class="hover:bg-hoverMenus focus:bg-hoverMenus"
            >
                <el-icon v-if="item.meta">
                    <component :is="item.meta.icon" class="menu-icon">
                    </component>
                </el-icon>
                <template #title>
                    <span class="text-indigo-200 font-medium">{{ $tc('menu.' + item.name.replace(" ", "_").toLowerCase()) }}</span>
                </template>
            </el-menu-item>
        </template>
    </template>
</template>

<script>
    export default {
        name: "menuTree",
        props: {
            menus: Array
        }
    }
</script>

<style scoped>
.menu-icon {
    color: #c7d2fe;
}

.el-menu-item.is-active .menu-icon {
    color: #409eff;
}
</style>

<template>
  <router-view v-if="getVersionCompleted" />
</template>

<script>
import { getVersion } from './api/version'

export default {
  name: 'App',
  created() {
    this.fetchVersionAndSetTitle()
  },
  data() {
    return {
      getVersionCompleted: false
    }
  },
  methods: {
    async fetchVersionAndSetTitle() {
      try {
        const response = await getVersion()
        const data = response.data;
        document.title = `AutoEase ${data.version}`
      } catch (error) {
        console.log(error)
      }
      this.getVersionCompleted = true
    }
  }
}
</script>

<style>
html,
body,
#app {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

import { http } from "../request";

export function getList() {
    return http({
        url: '/userList',
        method: 'get'
    })
}

export function getUser(username) {
    return http({
        url: '/user',
        method: 'get',
        params: {
            "username": username
        },
        timeout: 30000,
    })
}

export function addMember(data) {
    return http({
        url: '/user',
        method: 'post',
        data,
        timeout: 30000,
    })
}

export function modifyMember(userId, data) {
    return http({
        url: `/user/${userId}`,
        method: 'put',
        data,
        timeout: 30000,
    })
}

export {
    getList as userList
}

<template>
    <div id="nav">
        <el-menu
             :default-active="current_path"
             class="border-none bg-menus bg-opacity-75 text-center"
             mode="vertical"
             :collapse="open"
             :ellipsis="false"
             @select="handleMenuChange($event)"
             router
        >
            <div id="logo" class="bg-menus  bg-opacity-75 text-indigo-200" >
                <span> AutoEase </span>
            </div>
            <menuTree :menus="menus" />
        </el-menu>
    </div>
</template>

<script>
    import { storeToRefs } from 'pinia';
    import  menuTree  from './menuTree.vue'
    import { menuStore, userStore } from "../../store";

    export default {
        setup() {
            const store = menuStore();
            const { menus, open, current_path } = storeToRefs(store);
            // const user = userStore();

            // for (let i = 0; i < user.projects.length; i++) {
            //     if (user.project == user.projects[i].name)
            //     {
            //     if(user.projects[i].show_case_group == false)
            //     {
            //         for (let i = 0; i < menus.value.length; i++)
            //     {
            //         console.log(menus.value[i].name);
            //         if(menus.value[i].name == "Case Group"){
            //             menus.value.splice(i, 1);
            //             console.log("new array");
            //             console.log(menus);
            //         }
            //     }
            //     }
            //     }

            // }

            return {
                menus,
                open,
                current_path
            }
        },
        name: "Navigate",
        components: {
            menuTree
        },
      methods: {
          handleMenuChange(event) {
            window.localStorage.setItem('path', event)
          }
      }

    }
</script>

<style scoped>
    .el-menu-vertical > .el-menu-item {
      border-right: none;
    }
    #logo {
        width: 100%;
        height: 100%;
        margin-top: 8px;
    }
    #logo, span {
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        align-content: center;
        line-height: 50px;
    }
</style>

import Layout from '@/view/layout/index.vue'
import { LoginCallback } from '@okta/okta-vue'
import EmptyParent from '@/view/emptyParent.vue'

export const createRouters = [
    {
        name: 'home',
        path: '/',
        redirect: 'changeLists',
    },
    {
        name: 'Change List',
        path: '/changeLists',
        meta: {
            icon: 'Promotion',
            title: 'Change List',
        },
        component: Layout,
        children: [{
            name: 'Change List',
            path: '/changeLists',
            meta: {
                icon: 'Promotion',
                title: 'Change List',
            },
            component: () => import('../view/ChangeList/index.vue'),
            children: [],
            hidden: true,
            visible: true
        }, {
            name: 'Change List Info',
            path: 'info/:key',
            meta: {
                icon: 'Promotion',
                title: 'Change List Info',
                activeMenu: '/changeLists',
                requiresAuth: true
            },
            component: () => import('@/view/ChangeList/component/info.vue'),
            children: [
                {
                    name: 'CODEReport',
                    path: 'Code/report/:reportId',
                    component: () => import('@/view/TestReports/CommonReport/index.vue'),
                },
                {
                    name: 'ARTReport',
                    path: 'Art/report/:reportId',
                    component: () => import('@/view/TestReports/CommonReport/index.vue'),
                },
                {
                    name: 'AutomationReport',
                    path: 'Automation/report/:reportId',
                    component: () => import('@/view/TestReports/AutomationReport/index.vue'),
                    // children: [
                    //     {
                    //         name: 'UIRecord',
                    //         path: 'ui',
                    //         component: () => import('@/view/TestReports/AutomationReport/UIRecord/info.vue'),
                    //     },
                    //     {
                    //         name: 'UATRecord',
                    //         path: 'uat',
                    //         component: () => import('@/view/TestReports/AutomationReport/UATRecord/index.vue'),
                    //     },
                    //     {
                    //         name: 'GauntletRecord',
                    //         path: 'gauntlet',
                    //         component: () => import('@/view/TestReports/AutomationReport/GauntletRecord/index.vue'),
                    //     }
                    // ]
                }
                // {
                //     name: 'GauntletRecord',
                //     path: 'gauntlet',
                //     component: () => import('@/view/TestReports/GauntletReport/index.vue'),
                // }
            ],
            hidden: true
        },
        {
            name: 'Old Test Reports Info',
            path: 'info/:id',
            redirect: to => {
                const id = to.params.id
                return `/reports/info/${id}`
            }
        }
        ]
    },
    // {
    //     name: 'report',
    //     path: '/reports',
    //     meta: {
    //         icon: 'Promotion',
    //         title: 'Test Reports',
    //     },
    //     component: Layout,
    //     children: [{
    //         name: 'Test Reports',
    //         path: '/reports',
    //         meta: {
    //             icon: 'Promotion',
    //             title: 'Test Reports',
    //         },
    //         component: () => import('../view/TestReports/index.vue'),
    //         children: [],
    //         hidden: true
    //     },{
    //         name: 'Test Reports Info',
    //         path: 'UI/info/:id',
    //         meta: {
    //             icon: 'Promotion',
    //             title: 'Test Reports Info',
    //             activeMenu: '/reports',
    //             requiresAuth: true
    //         },
    //         component: () => import('@/view/TestReports/UIReport/info.vue'),
    //         children: [],
    //         hidden: true
    //     },
    //     {
    //         name: 'UATReport Test Reports Info',
    //         path: 'UAT/info/:id',
    //         meta: {
    //             icon: 'Promotion',
    //             title: 'Test Reports Info',
    //             activeMenu: '/reports',
    //             requiresAuth: true
    //         },
    //         component: () => import('@/view/TestReports/UATReport/index.vue'),
    //         children: [],
    //         hidden: true
    //     }
    //     ]
    // },
    {
        name: 'Case',
        path: '/case',
        meta: {
            icon: 'Document',
            title: 'Case',
            requiresAuth: true
        },
        component: Layout,
        children: [{
            name: 'Case',
            path: '/case',
            meta: {
                icon: 'Document',
                title: 'Case',
                requiresAuth: true
            },
            component: () => import('../view/Case/index.vue'),
            children: [],
            hidden: true,
            visible: true
        }]
    },
    {
        name: 'Case Group',
        path: '/case-group',
        meta: {
            icon: 'Files',
            title: 'Case Group',
            requiresAuth: true
        },
        component: Layout,
        children: [{
            name: 'Case Group',
            path: '/case-group',
            meta: {
                icon: 'Files',
                title: 'Case Group',
                requiresAuth: true
            },
            component: () => import('../view/CaseGroup/index.vue'),
            children: [],
            hidden: true,
            visible: true
        }]
    },
    {
        name: 'Device',
        path: '/device',
        meta: {
            icon: 'Monitor',
            title: 'Device',
            requiresAuth: true
        },
        component: Layout,
        children: [{
            name: 'Device',
            path: '/device',
            meta: {
                icon: 'Monitor',
                title: 'Device',
                requiresAuth: true
            },
            component: () => import('../view/Device/index.vue'),
            children: [],
            hidden: true,
            visible: true
        }]
    },
    {
        name: 'Manage',
        path: '/manage',
        meta: {
            icon: 'Menu',
            title: 'Manage',
            requiresAuth: true
        },
        component: Layout,
        children: [{
            name: 'Manage',
            path: '/manage',
            meta: {
                icon: 'Menu',
                title: 'Manage',
                requiresAuth: true
            },
            component: EmptyParent,
            children: [
                {
                    name: 'DeviceManage',
                    path: '/manage/device',
                    component: () => import('../view/Manage/index.vue'),
                },
                {
                    name: 'TaskManage',
                    path: '/manage/task',
                    component: () => import('../view/Manage/index.vue'),
                },
                {
                    name: 'WebsocketRequestManage',
                    path: '/manage/websocket-request',
                    component: () => import('../view/Manage/index.vue'),
                }

            ],
            visible: true
            // hidden: true
        }]
    },
    {
        name: 'Member',
        path: '/member',
        meta: {
            icon: 'User',
            title: 'Member',
            requiresAuth: true
        },
        component: Layout,
        children: [{
            name: 'Member',
            path: '/member',
            meta: {
                icon: 'User',
                title: 'Member',
                requiresAuth: true
            },
            component: () => import('../view/Member/index.vue'),
            children: [],
            hidden: true,
            visible: true
        }]
    },
    {
        name: 'Configure',
        path: '/configure',
        meta: {
            icon: 'Setting',
            title: 'Configure',
            requiresAuth: true
        },
        component: Layout,
        children: [{
            name: 'Configure',
            path: '/configure',
            meta: {
                icon: 'Setting',
                title: 'Configure',
                requiresAuth: true
            },
            component: () => import('../view/Configure/index.vue'),
            children: [],
            hidden: true,
            visible: true
        }]
    },
    {
        path: '/login/callback',
        component: LoginCallback,
    }
];

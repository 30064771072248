import Cookies from 'js-cookie'

const ACCESS_TOKEN = 'ACCESS_TOKEN'
const REFRESH_TOKEN = 'REFRESH_TOKEN'
const username = 'username'
const access_token = 'access_token'
const session = 'session'
const origin_url = 'origin_url'
const expiresDay = 30

export function setOriginUrl(token) {
    Cookies.set(origin_url, token)
}

export function setACCESS_TOKEN(token) {
    Cookies.set(ACCESS_TOKEN, token)
}

export function setREFRESH_TOKEN(token) {
    Cookies.set(REFRESH_TOKEN, token)
}

export function setUsername(token) {
    Cookies.set(username, token, {expires: expiresDay})
}

export function removeUsername() {
    Cookies.remove(username)
}

export function setAccessToken(token) {
    Cookies.set(access_token, token, {expires: expiresDay})
}

export function removeAccessToken() {
    Cookies.remove(access_token)
}

export function getACCESS_TOKEN() {
    let token = Cookies.get(ACCESS_TOKEN);
    return typeof token === 'undefined' ? '' : token;
}

export function getREFRESH_TOKEN() {
    return Cookies.get(REFRESH_TOKEN)
}

export function getUsername() {
    return Cookies.get(username)
}

export function getAccessToken() {
    return Cookies.get(access_token)
}

export function getOriginUrl() {
    return Cookies.get(origin_url)
}

export function removeSession() {
    Cookies.remove(session)
}
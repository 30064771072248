export default {
    oidc: {
        clientId: '0oa28d95ts6IetBxA697',
        issuer: 'https://jackalope.okta.com',
        redirectUri: window.location.origin + '/login/callback',
        scopes: ['openid', 'profile', 'email'],
    }
}

// export default {
//     oidc: {
//         clientId: '0oa25pu8tokzSgTI4697',
//         issuer: 'https://trial-4133402.okta.com/oauth2/default',
//         redirectUri: window.location.origin + '/login/callback',
//         scopes: ['openid', 'profile', 'email'],
//     }
// }

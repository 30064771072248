import vueApp from '@/main'
import { removeUsername } from "@/request/cookieConfig"

// 添加通用响应拦截器
export const applyResponseInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(function (response) {
        // 后端返回的data
        const data = response.data
        return data
    },
        function (error) {
            // 获取错误信息并展示
            const errorMessage = getErrorMessage(error)
            vueApp.$message.error(errorMessage)
            // http请求返回2xx以外的状态码
            if (error.response) {
                if (error.response.status === 403) {
                    removeUsername()
                    // window.location.href = '/'
                    vueApp.$router.push('/')
                    // useRefreshToken(error)
                }
            }
            return Promise.reject(error)
        })
}

const getErrorMessage = (error) => {
    if (error.response) {
        const data = error.response.data || {}
        // 尝试从各种可能的位置获取错误消息
        const message = data.message || data.error?.message || error.response.message
        if (message) {
            return message
        }
    }
    // 如果没有 error.response，返回错误的默认消息
    return error.message || 'Unknown error'
}